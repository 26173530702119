
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  name: 'ChooseRole'
})

export default class ChooseRole extends Vue {
  elems = [
    {
      id: 'lc',
      shortText: 'Личный кабинет',
      shortTextSecond: 'Для лизинговой компании',
      btnTxt: 'Войти',
      link: '/login/manager-lc',
      regTxt: 'Отправьте заявку на сотрудничество'
    },
    {
      id: 'ds',
      shortText: 'Личный кабинет',
      shortTextSecond: 'Для дилерского центра',
      btnTxt: 'Войти',
      link: '/login/manager-ds',
      regTxt: 'Отправьте заявку на сотрудничество'
    },
    {
          id: 'lc',
          shortText: 'Личный кабинет',
          shortTextSecond: 'Админа',
          btnTxt: 'Войти',
          link: '/login/admin',
          regTxt: ''
        },
    {
      id: 'sp',
      shortText: 'Нужна помощь?',
      shortTextSecond: 'support@poiskpl.ru',
      btnTxt: 'Написать',
      link: 'mailto:support@poiskpl.ru'
    }
  ]

  cookieWindow = false

  created() {
    if (!localStorage.getItem('cookie-window')) this.cookieWindow = true
  }

  closeCookie() {
    localStorage.setItem('cookie-window', '1')
    this.cookieWindow = false
  }
}
